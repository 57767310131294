.lista-nav a{
    text-decoration: none;
    font-family:Arial, Helvetica, sans-serif;
    font-size: large;
    color:rgba(42, 51, 209, 0.655);
}

.lista-nav a:hover{

    font-weight: 700;
    font-size:x-large;
    background-color:rgba(192, 234, 203, 0.773) ;

}

.lista-nav a {
    transition: background-color 0.5s linear 0.2s;
    transition: font-size 0.5s;
    transition-duration: 0.5s;
    transition-duration: 1s;
}


#imagen-tortuga img{
    border: 4px solid rgba(192, 234, 203) ;
    border-radius: 55%;
    width: 39%;
    height: 25%;
}

.lista-nav{
    margin-left: 10%;
    margin-right: 10%;
}

p{
    font-family: Arial, Helvetica, sans-serif;
    font-size: x-large;
}

a{
    text-decoration: none;
}

#iatabey{

    width: 25%;
    height: 10%;
}