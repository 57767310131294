td{
    text-align: center;
}

#imagen-login{

    width: 12%;
    height: 16%;
    border: 1px solid rgb(4, 11, 72) ;
    border-radius: 55%;
}


#form-login{

    padding: 5%;
    border: solid 1px rgb(150, 149, 149);
    border-radius: 3%;
  
  }

