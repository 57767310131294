
.buscador-div-standar #mam-table thead{

    background-color: rgb(34, 34, 90);
    color:gainsboro;

}

.buscador-div-standar #mam-table tbody{

    background-color: rgb(61, 61, 101);
    color:gainsboro;

}

.buscador-div-standar .show-scroll #mam-table thead tr {

   border: none;

}