
.show-scroll {
    overflow: scroll;
    width: 90%;
    margin: auto;
}

.show-scroll .table {

    background-color: rgb(150, 105, 189);
    color: aliceblue;
    
}

.show-scroll .table thead{
    background-color: rgb(62, 40, 85);
    font-weight: bold;
}

.div-mensajes{

    width:  90%;
    margin: auto;
}