
.buscador-div-standar {
    /*background-color: rgba(143, 206, 184, 0.8);*/
    background-color: rgba(117, 182, 204, 1);
    margin: auto;
    width: 80%;
    border-radius: 5px;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

#form-buscador{
    margin: auto;
    width: 90%;
    padding: 2%;
}

.buscador-div-standar #form-buscador button{

    background-color: rgb(54, 54, 221);
}

.buscador-div-standar #form-buscador input[type='text']{

    background-color: rgb(242, 242, 242);
}

.buscador-div-standar h3{
    color: rgb(60, 60, 117);
}

.buscador-div-standar .show-scroll {

    width: 86%;
    border-radius: 5px;
}