
.buscador-div-standar #mddn-table thead{

    background-color: rgb(37, 164, 35);
    color:gainsboro;

}

.buscador-div-standar #mddn-table tbody{

    background-color: rgb(85, 161, 84);
    color:gainsboro;

}

.buscador-div-standar .show-scroll #mddn-table thead tr {

   border: none;

}