
.buscador-div-standar #sObs-table{

    background-color: rgba(233, 0, 206, 0.3);
    margin: auto;
    border-radius: 2px;
    color:gainsboro;

}

.buscador-div-standar .td-claro{

    background-color: rgba(249, 155, 238, 0.3);
    
}

.buscador-div-standar .show-scroll #sObs-table thead tr {

   border: none;

}