
.buscador-div-standar #mndha-table thead{

    background-color: rgb(192, 117, 50);
    color:gainsboro;

}

.buscador-div-standar #mndha-table tbody{

    background-color: rgb(198, 139, 86);
    color:gainsboro;

}




.buscador-div-standar .td-claro{

    background-color: rgba(249, 203, 155, 0.3);
    
}

.buscador-div-standar .show-scroll #mndha-table thead tr {

   border: none;

}