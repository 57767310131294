
.buscador-div-standar #mnee-table thead{

    background-color: rgb(37, 99, 170);
    color:gainsboro;

}

.buscador-div-standar #mnee-table tbody{

    background-color: rgb(70, 118, 173);
    color:gainsboro;

}

.buscador-div-standar .show-scroll #mnee-table thead tr {

   border: none;

}