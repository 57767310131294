#observer-table{

    background-color: rgb(28, 67, 111);
    margin: auto;
    width: 86%;
    border-radius: 2px;
    color:gainsboro;

}

.buscador-div-standar .td-claro{

    background-color:rgb(60, 91, 125);
    
}

.buscador-div-standar .tr-borde{

    border-bottom: 0.01px solid aliceblue;
    
}