.fila-total{
    display: block;
}

button{
    color: blue;
}

.div-title h3{
    color: aliceblue;
}

.div-title h4{
    color: aliceblue;
}

hr{
    color:rgb(43, 83, 46);
}