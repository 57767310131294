.pimagen-tortuga img{

    border: 4px solid rgba(192, 234, 203) ;
    border-radius: 55%;
    width: 28%;
    height: 18%;
    margin-bottom: 1.8rem;
}

.content-table{
    
    background-color: rgba(0, 195, 255, 0.5);
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 5px;
}

#button-refresh{
    
    color: aliceblue;
    background-color: rgb(19, 48, 17);
    border-radius: 4px;
    width: 15%;
    margin: auto;
}