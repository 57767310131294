#tableUsers{

    background-color: rgb(116, 224, 123,0.5);
    height: 480px;
    overflow-y: scroll;
    overflow-x: scroll;
    scroll-behavior: smooth;
}

#tableUsers thead{
 
    background-color: rgb(52, 152, 59);
    color: aliceblue;
}