.App {
  text-align: center;
  background-color: rgba(5, 81, 5, 0.742);
  color: aliceblue;
  font-family: "Rubik", Sans-serif;
  margin: auto;

}

.div-standar{

  background-color: #6db95a;
  margin: 5%;
  display: inline-block;
  padding-top: 2px;
  padding-left: 30%;
  padding-right: 30%;
  padding: 2%;
  border: solid 8px rgb(56, 124, 61);
  border-radius: 2%;
  
}

.div-standar2{

  background-color: #6db95a;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 18%;
  margin-right: 18%;
  display: inline-block;
  padding-top: 2px;
  padding-left: 30%;
  padding-right: 30%;
  padding: 2%;
  border: solid 8px rgb(56, 124, 61);
  border-radius: 1%;
  
}
