#imagen-perfil{

    width: 20%;
    height: 12%;
    margin: auto;
    border: 4px solid rgba(192, 234, 203) ;
    border-radius: 55%;
}

#datosU{

    background-color: rgb(66, 171, 34,0.8);
}