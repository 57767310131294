#button-logout{
    
    background-color: rgb(19, 48, 17);
    border-radius: 4px;
    width: 15%;
    margin: auto;
}

#button-logout a{
    color: aliceblue;
    text-decoration: none;
    font-family:Arial, Helvetica, sans-serif;
    font-size: x-large;
    
}

#user-login{

    color: aliceblue;
    background-color: rgb(66, 171, 34,0.8);
    border-radius: 4px;
    width: 15%;
    margin: auto;
    padding: 0.3rem;
}