.div-button{
    display: block;
    background-color: rgb(34, 34, 238);
    border: 1 solid rgb(8, 8, 61);
    border-radius: 5px;
    margin-left: 5%;
    margin-right: 5%;
    padding: 0.5%;
}

.p_button{

    border: none;
    background-color:  rgb(34, 34, 238);
    color: aliceblue;
    font-family: Arial, Helvetica, sans-serif;
    font-size:x-large;

}

#p_button{

    border: none;
    background-color:  rgb(34, 34, 238);
    color: aliceblue;
    font-family: Arial, Helvetica, sans-serif;
    font-size:x-large;

}

.div-buttond{
    display: block;
    background-color:  rgb(146, 146, 183);
    border: 1 solid rgb(8, 8, 61);
    border-radius: 5px;
    margin-left: 5%;
    margin-right: 5%;
}


.p_buttond{

    border: none;
    background-color:  rgb(146, 146, 183);
    color: aliceblue;
    font-family: Arial, Helvetica, sans-serif;
    font-size:x-large;
}

#p_buttond{

    border: none;
    background-color:  rgb(146, 146, 183);
    color: aliceblue;
    font-family: Arial, Helvetica, sans-serif;
    font-size:x-large;
}